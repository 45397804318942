import React, { Fragment } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import tinytime from "tinytime"

import Layout from "../components/layout"
import { H1, UnstyledList } from "../components/commons"
import SEO from "../components/seo"

const Article = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 746px;
  margin: 0 auto;
  padding: 0 0.75rem;

  @media (min-width: 640px) {
    padding: 0 1rem;
  }

  img {
    display: block;
    margin-bottom: 0;
  }
`

const Header = styled.header`
  margin-bottom: 1.8rem;
  color: #615c53;

  @media (min-width: 640px) {
    margin-bottom: 3.2rem;
  }
`

const StyledH1 = styled(H1)`
  margin-bottom: 0;

  @media (min-width: 640px) {
    margin-bottom: 0.55rem;
  }
`
const Meta = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  font-style: italic;

  @media (min-width: 640px) {
    font-size: 1rem;
  }
`

const Time = styled.time`
  font-style: italic;
`
const Author = styled.span`
  a {
    color: #9a6e67;
    border-bottom: 1px solid rgba(154, 110, 103, 0.3);
    padding-bottom: 1px;
    text-decoration: none;
    transition: all 0.2s linear;

    &:hover {
      border-color: #9a6e67;
    }
  }
`

const ImageWrapper = styled.figure`
  margin-bottom: 1.3rem;

  figcaption {
    background-color: #fff9ed;
    font-family: franklin-gothic-urw, sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #615c53;
    font-size: 0.9375rem;
  }
`

const Clothing = styled(UnstyledList)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  padding: 1.3rem 1rem;
  margin-bottom: 1.3rem;
  border-top: 1px solid rgba(148, 142, 132, 0.3);
  border-bottom: 1px solid rgba(148, 142, 132, 0.3);

  @media (min-width: 640px) {
    margin-bottom: 2.6rem;
    justify-content: space-around;
    padding: 1.3rem 0;
  }

  li {
    margin-right: 0.75rem;

    @media (min-width: 640px) {
      margin-right: 0;
    }
  }
`

const Item = styled.li`
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: baseline;

  @media (min-width: 640px) {
    flex-direction: column;
  }

  span:first-child {
    font-family: franklin-gothic-urw, sans-serif;
    font-style: italic;
    font-weight: 400;
    color: #948e84;
    font-size: 0.875rem;
    line-height: 1.3rem;

    @media (min-width: 640px) {
      font-size: 1rem;
    }

    &:after {
      padding-left: 2px;
      font-size: 0.625rem;
      padding-right: 5px;
      content: "/";

      @media (min-width: 640px) {
        padding-left: 5px;
        font-size: 0.75rem;
      }
    }
  }

  span:last-child {
    font-family: franklin-gothic-urw, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.3rem;
    color: #615c53;

    @media (min-width: 640px) {
      font-size: 1.125rem;
    }
  }
`

export const query = graphql`
  query LookQuery($uid: String) {
    prismic {
      allLooks(uid: $uid) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
            }
            title
            image
            color
            author {
              ... on PRISMIC_Author {
                username
                website {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
            images {
              image1
            }
            clothing {
              brand {
                ... on PRISMIC_Brand {
                  name
                  _linkType
                }
              }
              category {
                ... on PRISMIC_Category {
                  name
                  _linkType
                }
              }
            }
          }
        }
      }
    }
  }
`

const Look = ({ data }) => {
  const doc = data.prismic.allLooks.edges.slice(0, 1).pop()
  if (!doc) return null

  const { node } = doc

  const title = node.title
  const uid = node._meta.uid
  const firstPublicationDate = node._meta.firstPublicationDate
  const color = node.color
  const img = node.image
  const clothing = node.clothing
  const author = node.author
  const images = node.images[0].image1 && node.images

  const dateTemplate = tinytime("{MMMM} {DD}")
  const date = dateTemplate.render(new Date(firstPublicationDate))

  return (
    <Layout>
      <SEO title={`look ${uid}`} />
      <Article>
        <Header>
          <StyledH1>{`look ${title}`}</StyledH1>
          <Meta>
            <span>{`— Published on `}</span>
            <Time dateTime={firstPublicationDate}>{date}</Time>
            {`, by `}
            <Author>
              <a href={author.website.url}>{author.username}</a>
            </Author>
            {` —`}
          </Meta>
        </Header>
        <Clothing>
          {clothing.map((it, index) => {
            return (
              <Item key={index}>
                <span>{it.category.name}</span>
                <span>{it.brand.name}</span>
              </Item>
            )
          })}
        </Clothing>
        <ImageWrapper style={{ backgroundColor: color }}>
          <img src={img.url} alt={img.alt} width={746} />
          <figcaption>{img.alt}</figcaption>
        </ImageWrapper>

        <Fragment>
          {images &&
            images.map((it, index) => {
              const { url, alt } = it.image1

              return (
                <ImageWrapper key={index} style={{ backgroundColor: color }}>
                  <img src={url} alt={alt} width={746} />
                  <figcaption>{alt}</figcaption>
                </ImageWrapper>
              )
            })}
        </Fragment>
      </Article>
    </Layout>
  )
}

export default Look
